import React from "react";

const Playground = () => {
  return (
    <div id="playground">
      <div className="max-w-7xl mx-auto p-10">
        <div className="space-y-10 md:space-y-0 md:grid md:grid-cols-2">
          <div className="md:flex md:flex-col md:justify-center">
            <h2 className="text-black text-2xl md:text-4xl lg:text-6xl font-bold mb-4">
              Playground
            </h2>

            <p className="md:text-lg text-gray-900">
              Here I'll share some of my personal project and some new stuff I
              played with. (Under Construction)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Playground;
