import React from "react";

const Navbar = () => {
  return (
    <div id="navbar">
      <nav className="bg-gray-200">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between">
            <div className="flex">
              <div>
                <a
                  href="https://zevka.my.id"
                  className="flex items-center py-5 px-2 text-gray-700 hover:text-gray-900"
                >
                  <span className="mr-2 hidden">Z </span>
                  <span className="font-bold">ZEVKA</span>
                  <span className="text-gray-600 opacity-70 text-sm">
                    .my.id
                  </span>
                </a>
              </div>
            </div>
            <div className="flex">
              <div className="hidden md:flex items-center space-x-1">
                <a
                  href="#profile"
                  className="py-2 px-3 text-gray-700 hover:text-gray-900 hover:bg-blue-200 rounded"
                >
                  About Me
                </a>
                <a
                  href="#blog"
                  className="py-2 px-3 text-gray-700 hover:text-gray-900 hover:bg-blue-200 rounded"
                >
                  Blog Post
                </a>
                <a
                  href="#playground"
                  className="py-2 px-3 text-gray-700 hover:text-gray-900 hover:bg-blue-200 rounded"
                >
                  Playground
                </a>
              </div>
            </div>

            <div className="hidden md:flex items-center space-x-1">
              <a href="https://github.com/thelazt16" className="py-2 px-3">
                <svg
                  className="octicon octicon-mark-github v-align-middle"
                  height="32"
                  viewBox="0 0 16 16"
                  version="1.1"
                  width="32"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"
                  ></path>
                </svg>
              </a>
              <a
                href="https://linkedin.com/in/thelazt16/"
                className="py-2 px-3 text-blue-600"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  className="global-nav__logo"
                >
                  <path
                    d="M34,2.5v29A2.5,2.5,0,0,1,31.5,34H2.5A2.5,2.5,0,0,1,0,31.5V2.5A2.5,2.5,0,0,1,2.5,0h29A2.5,2.5,0,0,1,34,2.5ZM10,13H5V29h5Zm.45-5.5A2.88,2.88,0,0,0,7.59,4.6H7.5a2.9,2.9,0,0,0,0,5.8h0a2.88,2.88,0,0,0,2.95-2.81ZM29,19.28c0-4.81-3.06-6.68-6.1-6.68a5.7,5.7,0,0,0-5.06,2.58H17.7V13H13V29h5V20.49a3.32,3.32,0,0,1,3-3.58h.19c1.59,0,2.77,1,2.77,3.52V29h5Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </a>
              <a
                href="/document/Febri_Eka_Setyawan_CV_Jan_12_2020.pdf"
                className="py-2 px-3 bg-blue-400 hover:bg-gray-400 text-white hover:text-black rounded transition duration-300"
              >
                Resume
              </a>
            </div>

            <div className="md:hidden flex items-center">
              <button className="mobile-menu-button">
                <svg
                  className="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className="mobile-menu hidden md:hidden">
          <a
            href="#profile"
            className="block py-2 px-4 text-sm hover:bg-gray-200"
          >
            About Me
          </a>
          <a href="#blog" className="block py-2 px-4 text-sm hover:bg-gray-200">
            Blog Post
          </a>
          <a
            href="#playground"
            className="block py-2 px-4 text-sm hover:bg-gray-200"
          >
            Playground
          </a>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
