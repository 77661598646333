import React from "react";

const Profile = () => {
  return (
    <div id="profile">
      <div className="max-w-7xl mx-auto p-10">
        <div className="space-y-10 md:space-y-0 md:grid md:grid-cols-2">
          <div className="md:mr-4">
            <div>
              <img
                className="w-full rounded-lg"
                src="https://unsplash.it/600/300?gravity=center"
                alt=""
              />
            </div>
          </div>
          <div className="md:flex md:flex-col md:justify-center">
            <h2 className="text-black text-2xl md:text-3xl lg:text-5xl font-bold mb-4">
              Hello, I'm Febri.
            </h2>

            <p className="md:text-lg text-gray-900">
              A web developer from Indonesia who talk PHP, JavaScript, HTML. I
              love to learn new stuff. Currently I'm learning ReactJS which I
              used to build this site.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
