import React from "react";
import Navbar from "./Navbar";
import Profile from "./Profile";
import Blog from "./Blog";
import Playground from "./Playground";
import Footer from "./Footer";

const Homepage = () => {
  return (
    <div id="home" className="bg-gray-100">
      <Navbar />
      <Profile />
      <Blog />
      <Playground />
      <Footer />
    </div>
  );
};

export default Homepage;
