import React from "react";

const Blog = () => {
  return (
    <div id="blog">
      <div className="max-w-7xl mx-auto p-10">
        <div className="space-y-10 md:space-y-0 md:grid md:grid-cols-2">
          <div className="md:flex md:flex-col md:justify-center">
            <h2 className="text-black text-2xl md:text-4xl lg:text-6xl font-bold mb-4">
              Blog Post
            </h2>

            <p className="md:text-lg text-gray-900">
              Here the latest posts from my blog. (Under Construction)
            </p>
          </div>
        </div>
        {/* card */}
        <div className="md:flex md:gap-4 my-2">
          <div className="md:w-1/2 bg-white rounded shadow-2xl">
            {/* image */}
            <div className="h-32 bg-gray-200 rounded-tr rounded-tl animate-pulse"></div>

            <div className="p-5">
              {/* title */}
              <div className="h-6 rounded-sm bg-gray-200 animate-pulse mb-4"></div>

              {/* content */}
              <div className="grid grid-cols-4 gap-1">
                <div className="col-span-3 h-4 rounded-sm bg-gray-200 animate-pulse"></div>
                <div className="h-4 rounded-sm bg-gray-200 animate-pulse"></div>

                <div className="col-span-2 h-4 rounded-sm bg-gray-200 animate-pulse"></div>
                <div className="col-span-2 h-4 rounded-sm bg-gray-200 animate-pulse"></div>

                <div className="h-4 rounded-sm bg-gray-200 animate-pulse"></div>
                <div className="col-span-3 h-4 rounded-sm bg-gray-200 animate-pulse"></div>
                <div className="col-span-2 h-4 rounded-sm bg-gray-200 animate-pulse"></div>
                <div className="h-4 rounded-sm bg-gray-200 animate-pulse"></div>
              </div>
            </div>
          </div>
          <div className="my-8 md:my-0 md:w-1/2 bg-white rounded shadow-2xl">
            {/* image */}
            <div className="h-32 bg-gray-200 rounded-tr rounded-tl animate-pulse"></div>

            <div className="p-5">
              {/* title */}
              <div className="h-6 rounded-sm bg-gray-200 animate-pulse mb-4"></div>

              {/* content */}
              <div className="grid grid-cols-4 gap-1">
                <div className="col-span-3 h-4 rounded-sm bg-gray-200 animate-pulse"></div>
                <div className="h-4 rounded-sm bg-gray-200 animate-pulse"></div>

                <div className="col-span-2 h-4 rounded-sm bg-gray-200 animate-pulse"></div>
                <div className="col-span-2 h-4 rounded-sm bg-gray-200 animate-pulse"></div>

                <div className="h-4 rounded-sm bg-gray-200 animate-pulse"></div>
                <div className="col-span-3 h-4 rounded-sm bg-gray-200 animate-pulse"></div>
                <div className="col-span-2 h-4 rounded-sm bg-gray-200 animate-pulse"></div>
                <div className="h-4 rounded-sm bg-gray-200 animate-pulse"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
