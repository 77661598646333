import React from "react";

const Footer = () => {
  return (
    <div id="footer" className="text-sm text-center md:text-lg">
      <div className="bg-gray-200">
        <div className="max-w-7xl mx-auto px-8">
          <div className="flex justify-center">
            <div className="flex space-x-4">
              <div className="py-4">
                @2021 Build with
                <a href="https://reactjs.org/" className="text-blue-600">
                  {" "}
                  ReactJS{" "}
                </a>
                and
                <a href="https://tailwindcss.com/" className="text-blue-600">
                  {" "}
                  TailwindCSS
                </a>
                . Hosted on
                <a
                  href="https://firebase.google.com/"
                  className="text-blue-600"
                >
                  {" "}
                  Firebase
                </a>
                . SSL provided by
                <a
                  href="https://www.cloudflare.com/ssl/"
                  className="text-blue-600"
                >
                  {" "}
                  Cloudfare
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
